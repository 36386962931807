import React from "react"
import ServiceSidebarArchitecture from "./ServiceSidebarArchitecture"
import details1 from "../../assets/images/services/banner-system-monitoring.jpg"

const SystemMonitoringContent = ({ location }) => {
  return (
    <section className="services-details-area ptb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="services-details-image">
              <img src={details1} alt="about" />
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <ServiceSidebarArchitecture location={location} />
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="services-details-desc">
              <span className="sub-title">SOFTWARE ARCHITECTURE DESIGN</span>

              <h3>System Monitoring Design</h3>
              <h5>
                Production Monitoring with aggregated logging and altering in
                distributed systems. Analytical and audit events gathering from
                frontend mobile and backend modules.
              </h5>

              <p>
                Your customers shouldn’t be the ones to tell you that your
                services don’t work. You should already know it. With the
                emergence of Kubernetes and swarm solutions, monitoring has
                become even more complex. Micro-services and distributed
                processing across components make it difficult to track, analyse
                and understand what is happening in your systems.
              </p>

              <div className="row align-items-center">
                <div className="col-lg-12 col-md-12">
                  <div className="content">
                    <h3>
                      Here's what we can do to improve monitoring in your
                      landscape:
                    </h3>
                    <ul>
                      <li>
                        Prepare a complete monitoring strategy for your solution
                      </li>
                      <li>
                        Build a centralised tracking system to analyse
                        distributed processes
                      </li>
                      <li>
                        Add message gathering from all components of your
                        landscape
                      </li>
                      <li>
                        Add monitoring to all hosts in your infrastructure
                      </li>
                      <li>Add monitoring to all containers in clusters</li>
                      <li>
                        Add metric monitoring for resources like microservices,
                        databases, middleware components, 3rd party systems,
                        etc.
                      </li>
                      <li>
                        Add service health monitoring so you can see where
                        problems are
                      </li>
                      <li>
                        Add alerts for important metrics so your team can react
                        earlier before real problems occur
                      </li>
                      <li>
                        Add alerts for critical components of infrastructure
                      </li>
                      <li>
                        Add event monitoring when components fail and services
                        switch to other resources
                      </li>
                      <li>
                        Add error tracking and fix monitoring for application
                        errors
                      </li>
                      <li>
                        Advise on redundancy so that your infrastructure and
                        services are reliable for your customers
                      </li>
                      <li>
                        Advise on business event gathering from backend and
                        frontend components like, number of currently logged
                        users, so you can understand your users better.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <p>
                With a proper monitoring system, the next time something doesn’t
                work, you will be able to fix it before your stakeholders or
                customers notice.
              </p>

              <h3 className="pt-30">Technology</h3>
              <p>
                Technologies & tools that we typically use in our monitoring
                setups.
              </p>
              <ul className="technologies-features">
                <li>
                  <span>Kubewatch</span>
                </li>
                <li>
                  <span>ELK/EFK Stack</span>
                </li>
                <li>
                  <span>Grafana</span>
                </li>
                <li>
                  <span>Prometheus</span>
                </li>
                <li>
                  <span>Fluentd</span>
                </li>
                <li>
                  <span>Spring Actuator</span>
                </li>
                <li>
                  <span>Kiali</span>
                </li>
                <li>
                  <span>Jaeger</span>
                </li>
                <li>
                  <span>Sentry</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SystemMonitoringContent
